import React, { useEffect, useState } from 'react';
import './styles.css';
import './resizer.css';
import { Canvas, Image } from 'fabric';

function YapMaker() {
  const [canvas, setCanvas] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null); // Store the uploaded background image
  const [overlayImage, setOverlayImage] = useState(null); // Store the selected overlay image

  useEffect(() => {
    // Initialize Fabric.js canvas
    const fabricCanvas = new Canvas('fabricCanvas', {
      width: 1280,
      height: 720,
      backgroundColor: '#ffffff',
    });
    setCanvas(fabricCanvas);

    // Cleanup on component unmount
    return () => {
      fabricCanvas.dispose();
    };
  }, []);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = function (f) {
        Image.fromURL(f.target.result, (img) => {
          img.set({
            left: 0,
            top: 0,
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height,
            selectable: false,
          });
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
          setBackgroundImage(img);
          canvas.clear(); // Clear the canvas before setting the new background
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas)); // Set the new background image
        });
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please upload a valid image file.');
    }
  };

  const handleThumbnailClick = (imageSrc) => {
    if (canvas) {
      canvas.clear(); // Clear the canvas to remove any previous images
      if (backgroundImage) {
        canvas.setBackgroundImage(backgroundImage, canvas.renderAll.bind(canvas)); // Restore background image
      }
      Image.fromURL(imageSrc, (img) => {
        img.set({
          left: canvas.width / 2 - img.width / 2,
          top: canvas.height / 2 - img.height / 2,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        canvas.add(img);
        canvas.renderAll();
        setOverlayImage(img);
      });
    }
  };

  const handleDownload = () => {
    if (canvas) {
      canvas.discardActiveObject(); // Deselect any active object to prevent unwanted overlays
      const dataURL = canvas.toDataURL({
        format: 'jpeg',
        quality: 1,
      });
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'canvas-image.jpg';
      link.click();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Add the logo at the top */}
      <img 
        src="/postalmaker1.png" 
        alt="Logo" 
        style={{ 
          width: 'auto', 
          height: '100px', 
          display: 'block', 
          margin: '0 auto 20px auto' 
        }} 
      />

      {/* Container for canvas and thumbnails */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Canvas and buttons container */}
        <div id="canvas-container" style={{ marginRight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <input type="file" id="upload-button" accept="image/*" onChange={handleUpload} style={{ marginBottom: '10px' }} />
          <button id="download-button" onClick={handleDownload} style={{ marginBottom: '20px' }}>Download Meme</button>
          <canvas id="fabricCanvas"></canvas>
        </div>

        {/* Thumbnails next to canvas */}
        <div
          id="thumbnail-container"
          style={{
            width: '150px',
            height: '500px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: 'transparent',
          }}
        >
          {['/catslay1.png', '/petition1.png', '/machine1.png', '/rifle1.png', '/grenade1.png', '/nuke1.png'].map((imgSrc, index) => (
            <div
              key={index}
              style={{
                width: '80px',
                height: '80px',
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: 'cover',
                cursor: 'pointer',
                border: '1px solid white',
              }}
              onClick={() => handleThumbnailClick(imgSrc.replace('1', ''))}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default YapMaker;
