import React from 'react';
import './App.css';
import Model from './Model';
import SubModel from './SubModel';
import YapMaker from './YapMaker';

function App() {
  return (
    <div className="App">
      <div className="Overlay" id="Overlay">
        <a className="Overlay">
          <img src="click_to_enter_screen.png" alt="Logo" style={{ width: 'auto', height: '500px', maxWidth: '100%', cursor: 'pointer' }} />
        </a>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>CLICK TO ENTER</div>
        <script src="click_to_enter.js"></script>
      </div>
      <header className="App-header">
        <a className="logo"><img src="Postalcat.png" alt="Logo" /></a>
      </header>
      <div className="thumbnails">
        <a href="https://dexscreener.com/"><img src="dexscreenpostal.png" alt="thumbnail1" /></a>
        <a href="https://t.me/postalcatsolana"><img src="tg.png" alt="thumbnail2" /></a>
        <a href="https://x.com/postalcatsolana"><img src="X.png" alt="thumbnail3" /></a>
        <a href="https://pump.fun/HRQX11NeXJcSW3rCsuZvdkUiYUJzom7txG2Pu4hT3Cqu"><img src="pumpfun.png" alt="thumbnail5" /></a>
      </div>
      <div className="container">
        <div className="side-models left">
          <SubModel modelPath="/catgun1.glb" />
          <SubModel modelPath="/catgun2.glb" />
          <SubModel modelPath="/catgun3.glb" />
        </div>
        <div className="model-container-wrapper">
          <div className="model-container">
            <Model />
          </div>
        </div>
        <div className="side-models right">
          <SubModel modelPath="/catgun1.glb" />
          <SubModel modelPath="/catgun2.glb" />
          <SubModel modelPath="/catgun3.glb" />
        </div>
      </div>

      {/* Position the YapMaker component below the other content */}
      <div className="yapmaker-container">
        <YapMaker />
      </div>

      {/* Add the large h2-sized text below the YapMaker container */}
{/* Add the large h2-sized text below the YapMaker container */}
<div style={{ textAlign: 'center', marginTop: '20px', fontSize: '2em', fontWeight: 'bold', color: 'white' }}>
  I CAN'T FIGURE OUT HOW TO FUCKING DEBUG THIS PISS MAKER SHIT, FUCK THIS, I GIVE UP, ANYWAYS SIGN MY PETITION!
</div>

      <div style={{ textAlign: 'center', marginTop: '10px' }}>aaaaaa</div>
    </div>
  );
}

// Function to handle click events
const handleClick = () => {
  const overlay = document.getElementById("Overlay");
  if (overlay) {
    overlay.style.opacity = '0';
    overlay.style.zIndex = '-1';
  } else {
    console.error('Overlay element not found');
  }
};

// Add click event listener to the document
document.addEventListener('click', handleClick);

export default App;
