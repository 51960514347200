import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

function SubModel({ modelPath }) {
  const mountRef = useRef(null);

  useEffect(() => {
    // Scene, Camera, Renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(30, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Adjust camera position to a side view (left or right)
    camera.position.set(6, 0, 0); // Set camera to the right side
    camera.lookAt(0, 0, 0); // Make the camera look at the center of the scene

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    directionalLight.position.set(10, 10, 10).normalize();
    scene.add(directionalLight);

    // Load Model
    const loader = new GLTFLoader();
    loader.load(modelPath, (gltf) => {
      const model = gltf.scene;
      scene.add(model);

      // Initial rotation
      model.rotation.y = 0.5;
      model.position.y -= 1; // Move down by 1.5 units

      // Animation
      const animate = function () {
        requestAnimationFrame(animate);
        model.rotation.y += 0.01;  // Continue rotation
        renderer.render(scene, camera);
      };

      animate();
    });

    // Clean up event listener on component unmount
    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, [modelPath]);

  return (
    <div
      style={{ width: '250px', height: '250px' }}
      ref={mountRef}
    />
  );
}

export default SubModel;
